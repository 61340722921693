<template lang="html">
  <div class="">
    About
  </div>
</template>

<script>
export default {
};
</script>

<style lang="css" scoped>
</style>
